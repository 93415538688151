<template>
    <div>
        <v-dialog v-model="$store.state.dialog" width="800" persistent transition="dialog-top-transition">
            <v-card flat class="card">
                <v-toolbar outlined flat color="grey lighten-4">
                    <v-toolbar-title>{{ $store.state.itemDetails.dialogType == "add" ? "إضافة" : "تعديل" }} {{ this.$route.meta.single }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn @click="close()" icon>
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
                    <v-container>
                        <v-card outlined>
                            <v-container>
                                <v-row>

                                    <!-- fullName -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            الإسم الثلاثي
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field
                                            v-model="item.fullName"
                                            placeholder="الإسم الثلاثي"
                                            :rules="[$global.state.required()]"
                                            color="info"
                                            required
                                            filled
                                            outlined
                                            hide-details="auto"
                                        />
                                    </v-col>

                                    <!-- userName -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            إسم المستخدم
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field
                                            v-model="item.userName"
                                            hint="يجب إدخال إسم المستخدم بالإنكليزي"
                                            placeholder="إسم المستخدم ( بالإنكليزي )"
                                            color="info"
                                            :rules="[$global.state.required()]"
                                            required
                                            filled
                                            outlined
                                            hide-details="auto"
                                        />
                                    </v-col>

                                    <!-- phoneNumber -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            رقم الهاتف
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field
                                            v-model="item.phoneNumber"
                                            v-mask="$phoneMask"
                                            dir="ltr"
                                            placeholder="+964 ### ### ####"
                                            color="info"
                                            required
                                            filled
                                            outlined
                                            hide-details="auto"
                                        />
                                    </v-col>

                                    <!-- email -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2"> البريدالإلكتروني<span class="required">*</span></h4>
                                        <v-text-field
                                            v-model="item.email"
                                            placeholder="البريدالإلكتروني"
                                            color="info"
                                            :rules="[$global.state.emailFormat(),]"
                                            required
                                            filled
                                            outlined
                                            hide-details="auto"
                                        />
                                    </v-col>

                                    <!-- password -->
                                    <v-col cols="12" md="6" v-if="$store.state.itemDetails.dialogType == 'add'">
                                        <h4 class="mb-2">كلمة المرور<span class="required">*</span></h4>
                                        <v-text-field
                                            v-model="item.password"
                                            dir="ltr"
                                            color="info"
                                            placeholder="كلمة المرور"
                                            :rules="[$global.state.required()]"
                                            required
                                            filled
                                            outlined
                                            hide-details="auto"
                                        />
                                    </v-col>

                                    <!-- addres -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">العنوان</h4>
                                        <v-text-field
                                            v-model="item.addres"
                                            placeholder="العنوان"
                                            color="info"
                                            filled
                                            outlined
                                            hide-details="auto"
                                        />
                                    </v-col>

                                    <!-- groupId -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2"> الصلاحية<span class="required">*</span></h4>
                                        <v-autocomplete
                                            clearable
                                            v-model="item.groupId"
                                            item-text="name"
                                            color="info"
                                            item-value="id"
                                            :items="$global.state.authGroups"
                                            placeholder="صلاحية المستخدم"
                                            :loading="$global.state.loading"
                                            no-data-text="لا توجد بيانات"
                                            hide-details
                                            outlined
                                            filled
                                        />
                                    </v-col>

                                    <!-- imageUrl -->
                                    <v-col cols="12" md="6" v-if="$store.state.itemDetails.dialogType == 'add'">
                                        <h4 class="mb-2">صورة شخصية</h4>
                                        <FileUpload
                                            accept="image/*;capture=camera"
                                            formData="files"
                                            endPoint="UploadFile/postUploadFiles"
                                            buttonText="رفع صورة"
                                            icon="true"
                                        />
                                    </v-col>

                                </v-row>
                            </v-container>
                        </v-card>
                        <!-- actions -->
                        <v-col cols="12" md="12">
                            <v-row class="mt-2">
                                <v-btn type="submit" :loading="$global.state.loading" x-large color="primary secondary--text" class="btn" :disabled="!valid">حفظ</v-btn>
                                <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                            </v-row>
                        </v-col>
                    </v-container>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    components: {
        FileUpload : () => import('@/components/FileUpload.vue')
    },
    data() {
        return {
            valid: false,
            item: {},
        };
    },

    mounted() {
        this.reset();

        this.$eventBus.$on("fill-fields", () => {
            this.item = this.$store.state.itemDetails;
            this.item.groupId = this.$store.state.itemDetails.group.id;
            
        });
        if (this.$global.state.authGroups == "") this.getAuthGroups();
    },

    methods: {
        reset() {
            this.item = {
                fullName: "",
                userName: "",
                email: "",
                phoneNumber: "",
                password: "",
                imageUrl: "",
                addres: "",
                nearPoint: "",
                groupId: "",
            };
            this.$store.commit("clearFiles");
        },

        submit() {
             this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                // this.item.role = this.$route.meta.roleName;
                this.item.phoneNumber = this.item.phoneNumber.replace(/,/g, "");
                // this.item.userName = this.item.email;
                if (this.$store.state.itemDetails.dialogType == "add") {
                    this.addItem();
                } else {
                    this.editItem();
                }

            }
        },

        async addItem() {
            this.$global.state.loading = true;
            // upload imageUrl
            if (this.$store.state.files[0] != null) {
                let formData = new FormData();
                for (const i of Object.keys(this.$store.state.files)) {
                    formData.append("files", this.$store.state.files[i]);
                }
                await this.$http.post("/UploadFile/postUploadFiles", formData).then((res) => {this.item.imageUrl = res.data.result.fileList[0].path;});
            }
            await this.$http
                .post(`${this.$route.meta.endPoint}`, this.item)
                .then((res) => {
                    this.$eventBus.$emit(`refresh`);
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `${res.data.message}`,
                        type: "success",
                    });
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        async editItem() {
            this.$global.state.loading = true;
            this.$http
                .put(`${this.$route.meta.endPoint}/${this.item.id}`, this.item)
                .then((res) => {
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `${res.data.message}`,
                        type: "success",
                    });
                    this.$eventBus.$emit(`refresh`);
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        getAuthGroups() {
            this.$global.dispatch(`getAuthGroups`);
        },

        close() {
            this.$store.commit("setDialog");
            this.reset();
            this.resetValidation();
            this.$store.commit("setItemDetails", {});
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>
